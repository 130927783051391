import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { PropsWithChildren, Suspense, useMemo } from "react";
import { FullPageLoader } from "../../components/core/Loader/FullPageLoader";
import { getTheme } from "../../mui/theme";
import { LandingPageView } from "../../components/landingPage/LandingPageView";

const RootWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useMemo(() => getTheme(), []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Suspense fallback={<FullPageLoader />}>{children}</Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

/**
 * The main router for the landing page routes. This will contain
 * less dependencies than the `RootRoute` and is used in the
 * landing page part of the app (bundle).
 */
export const LandingPageRootRoute: React.FC = () => {
  return (
    <RootWrapper>
      <LandingPageView />
    </RootWrapper>
  );
};
